.Banner {
  padding: 4rem 0;
  text-align: right;

  @media screen and (max-width: $w_m) {
    padding: 2rem 0;
  }

  img {
    &.img1 {
      width: 85%;
      margin-left: auto;

      @media screen and (max-width: $w_m) {
        display: none;
      }
    }

    &.img2 {
      display: none;

      @media screen and (max-width: $w_m) {
        display: block;
        margin: 0 0 0 auto;
      }
    }
  }
}

.title_one {
  text-align: center;
  padding: 0 0 15rem 0;

  @media screen and (max-width: $w_m) {
    padding: 0 0 2.75rem 0;
  }

  h2 {
    margin: 0 0 1.5rem 0;
  }

  p {
    max-width: 795px;
    margin: 0 auto;
    font-size: 24px;

    @media screen and (max-width: $w_m) {
      font-size: 14px;
    }
  }
}

.title_two {
  @include flex_box();
  padding: 0 0 15rem 0;

  @media screen and (max-width: $w_m) {
    flex-flow: row wrap;
    padding: 0 0 7.5rem 0;
  }

  img {
    width: 50%;

    @media screen and (max-width: $w_m) {
      width: 100%;  
    }
  }

  div {
    width: 50%;
    padding: 0 0 0 1.25rem;
    text-align: center;

    @media screen and (max-width: $w_m) {
      padding: 2rem 0 0 0;
      width: 100%;
    }

    h2 {
      position: relative;
      margin: 0 0 3rem 0;

      @media screen and (max-width: $w_m) {
        margin: 0 0 1rem 0;
      }

      span {
        border-bottom: 8px solid $color_red;

        @media screen and (max-width: $w_m) {
          border-bottom: 5px solid $color_red;
        }
      }
    }

    p {
      font-size: 24px;
      text-align: center;

      @media screen and (max-width: $w_m) {
        font-size: 14px;
      }
    }
  }
}

.bg_wall {
  height: 600px;
  background: url(../images/img_home_gmsay.jpg) center;
  background-size: cover;  
  color: #fff;
  @include flex_box();
  margin: 0 0 15rem 0;

  @media screen and (max-width: $w_m) {
    height: 320px;
    padding: 0 1rem;
    box-sizing: border-box;
    margin: 0 0 7.5rem 0;
  }

  div {
    width: 570px;
    height: 300px;
    flex-flow: column;
    @include flex_box($jc: space-between, $ai: flex-start);
    font-size: 24px;

    @media screen and (max-width: $w_m) {
      width: 100%;
      max-width: 336px;
      height: 180px;
      font-size: 14px;
    }
  }

  p {
    &:nth-child(2) {
      padding-left: 10%;
    }

    &:nth-child(3) {
      padding-left: 23%;
    }

    &:nth-child(4) {
      padding-left: 35%;
    }

    &:nth-child(5) {
      text-align: right;
      width: 100%;
      padding-right: 1.5%;
    }
  }
}

.team {
  margin: 0 0 6.25rem 0;

  @media screen and (max-width: $w_m) {
    margin: 0 0 2.25rem 0;
  }

  &.company {
    margin: 12.5rem 0 6.25rem 0;

    @media screen and (max-width: $w_m) {
      margin: 6.25rem 0 2.25rem 0;
    }

    div {
      ul {
        li {
          p {
            

            &.big {
              font-size: 48px;
              padding: 0 0 4rem 0;     
              line-height: 68.48px;         

              @media screen and (max-width: $w_m) {
                font-size: 24px;
                padding: 0 0 2rem 0;
                line-height: 34.49px;         
              }
            }

            &.space {
              padding: 0 0 2rem 0;
            }
          }
        }
      }
    }
  }

  h2 {
    font-size: 48px;
    font-weight: 700;
    padding: 0 5px 0 20px;
    background: $primary;
    letter-spacing: 14.4px;
    color: #fff;
    display: inline-block;
    margin: 0 0 3rem 0;
    height: 89px;
    line-height: 89px;

    @media screen and (max-width: $w_m) {
      font-size: 20px;
      padding: 8px 16px;      
      height: auto;
      line-height: normal;
      letter-spacing:6px;
    }
    
  }

  div {
    @include flex_box($jc: flex-start);

    @media screen and (max-width: $w_m) {
      flex-flow: row wrap;
    }

    img {
      width: 482px;

      @media screen and (max-width: $w_m) {
        width: 266px;
      }
    }

    ul {
      list-style: none;
      padding: 0 0 0 4rem;
      margin: 0;

      @media screen and (max-width: $w_m) {
        width: 100%;
        padding: 0;
        padding: 0.25rem 0 0 0;
      }

      li {
        padding: 1.3125rem 0;

        @media screen and (max-width: $w_m) {
          padding: 0.75rem 0;
        }

        p {
          font-size: 20px;
          line-height: 28.74px;

          @media screen and (max-width: $w_m) {
            font-size: 14px;
            line-height: 20.12px;
          }

          &.big {
            font-size: 24px;
            font-weight: 900;
            line-height: 34.49px;

            @media screen and (max-width: $w_m) {
              font-size: 16px;
              line-height: 22.99px;
            }
          }
        }
      }
    }
  }
}

.case_box {
  position: relative;

  & > .w_box{
    @media screen and (max-width: $w_m) {
      padding: 0;
    }
  }

  &:after {
    content: "";
    background: #e3e3e3;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: calc(100% - 236px);
    bottom: 0;
  }

  &.performance{
    margin: 6.25rem 0 0 0;

    @media screen and (max-width: $w_m) {
      margin: 3rem 0 0 0;
    }
  }

  .case {
    position: relative;

    .slick-arrow {
      font-size: 0;
      -webkit-appearance: none;
      padding: 0;
      border: 0;
      position: absolute;
      top: 61%;
      z-index: 2;

      &::before {
        content: "";
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        background: url(../images/icon_pre.svg) no-repeat center / 30px;
        display: block;
        background-color: #fff;
        transition: background-color 0.3s ease-out;

        @media screen and (max-width: $w_m) {
          width: 40px;
          height: 40px;
        }
      }

      &:hover {
        &::before {
          background-color: #f8f8f8;
        }

        &.slick-next {
          &::before {
            background-color: #f8f8f8;
          }
        }
      }

      &.slick-prev {
        left: 0;
      }

      &.slick-next {
        right: 0;

        &::before {
          background: url(../images/icon_next.svg) no-repeat center / 30px;
          background-color: #fff;
        }
      }
    }

    .text {
      font-weight: 900;
      font-size: 24px;
      padding: 0 0 3rem 0;

      @media screen and (max-width: $w_m) {
        font-size: 14px;
        padding: 0 1rem 1rem 1rem;
      }

      .name {
        font-size: 48px;
        padding: 0.75rem 0 0.75rem 0;
        border-bottom: 4px solid $color_red;
        margin: 0 0 1rem 0;
        display: table;

        @media screen and (max-width: $w_m) {
          font-size: 24px;
          padding: 0.75rem 0 0.25rem 0;
          border-bottom: 2px solid $color_red;
        }
      }

      .lo {
        font-weight: 600;
      }
    }

    .img_box {
      background: #e3e3e3;

      .w_box {
        max-width: 1000px;
      }

      img {
        margin: 0 auto;
      }
    }
  }
}

.winning {
  h2 {
    text-align: center;
    margin: 0 0 3rem 0;

    @media screen and (max-width: $w_m) {
      margin: 0 0 1rem 0;
    }
  }

  img {
    margin: 0 auto;
    display: table;
    &.img1 {
      @media screen and (max-width: $w_m) {
        display: none !important;
      }
    }

    &.img2 {
      display: none;
      @media screen and (max-width: $w_m) {
        display: table;        
      }
    }
  }
  ul{
    list-style: none;
    font-size: 14px;
    margin: 3rem 0 0 0;

    @media screen and (max-width: $w_m) {
      margin: 1rem 0 0 0;
      padding: 0;
      font-size: 12px;
    }
    li{
      @include flex_box();
      flex-flow: row wrap;
      p{
        margin: 0 0.5rem;
        &:before{
          content: "・";
        }
        &.m{
          display: none;

          @media screen and (max-width: $w_m) {
            display: inline-block;
          }
        }
      }

      &.two{
        @media screen and (max-width: $w_m) {
          display: none;
        }
      }
    }
  }
}