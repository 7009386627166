$color_red: #d7000f;

$tr_op: opacity 0.3s ease-out;
$tr_c: color 0.3s ease-out;
$tr_bg: background 0.3s ease-out;
$tr_tr: transform 0.3s ease-out;

$w_m: 1023px;

@mixin flex_box($jc: center, $ai: center) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
}

body {
  background: #f8f8f8;
  font-family: $font_Serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

h2 {
  font-weight: 900;
  font-size: 36px;

  @media screen and (max-width: $w_m) {
    font-size: 24px;
  }
}

.Box {
  padding: 0 0 240px 0;

  @media screen and (max-width: $w_m) {
    padding: 60px 0 7.5rem 0;
  }
}

.w_box {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.top_bar {
  padding: 0 32px;
  @include flex_box($jc: flex-start);
  height: 80px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: $w_m) {
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    box-shadow: none;
    z-index: 1;
  }

  .switch {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    @include flex_box;
    z-index: 4;
    opacity: 0;
    pointer-events: none;
    transition: $tr_op;

    @media screen and (max-width: $w_m) {
      opacity: 1;
      pointer-events: inherit;
    }

    &.focus {
      p {
        span {
          &.line1 {
            width: 30px;
            transform: rotate(45deg);
            top: calc(50% - 2px);
          }

          &.line2 {
            opacity: 0;
          }

          &.line3 {
            width: 30px;
            transform: rotate(-45deg);
            bottom: calc(50% - 2px);
          }
        }
      }
    }

    p {
      width: 32px;
      height: 24px;
      position: relative;

      span {
        position: absolute;
        background: $primary;
        height: 4px;
        width: 26px;

        &.line1 {
          width: 32px;
          background: $color_red;
          transition: $tr_tr, top 0.3s ease-out;
        }

        &.line2 {
          bottom: 0;
          top: 10px;
          transition: $tr_op;
        }

        &.line3 {
          bottom: 0;
          transition: $tr_tr, bottom 0.3s ease-out;
        }
      }
    }
  }

  h1 {
    margin: 0 30px 0 0;

    @media screen and (max-width: $w_m) {
      @include flex_box($jc: flex-start);
      height: 60px;
      position: fixed;
      top: 0;
      width: 100%;
      padding: 0 20px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      z-index: 3;
      background: #fff;
      margin: 0;
    }

    a {
      @include flex_box;

      font-size: 28px;
      font-weight: 900;

      @media screen and (max-width: $w_m) {
        font-size: 24px;
      }

      img {
        width: 20px;
        margin: 0 21px 0 0;

        @media screen and (max-width: $w_m) {
          width: 15px;
          margin: 0 15px 0 0;
        }
      }
    }
  }

  .menu {
    @media screen and (max-width: $w_m) {
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      background: rgba(#FFF, 0.3);
      backdrop-filter: blur(10px);
      opacity: 0;
      pointer-events: none;
      transition: $tr_op;
      height: calc(100vh - 60px);
    }

    &.focus {
      opacity: 1;
      pointer-events: inherit;
    }

    ul {
      list-style: none;
      @include flex_box;
      margin: 0;
      padding: 0;

      @media screen and (max-width: $w_m) {
        display: block;
      }

      li {
        position: relative;

        &.focus_on {
          >a {
            &::after {
              opacity: 1;
            }
          }
        }

        &.focus {
          >a {
            &::after {
              opacity: 1;
            }
          }

          div {
            opacity: 1;
            pointer-events: inherit;
          }
        }

        @media screen and (min-width: $w_m) {
          &:hover {
            >a {
              &::after {
                opacity: 1;
              }
            }

            div {
              opacity: 1;
              pointer-events: inherit;
              display: block !important;
            }
          }
        }

        >a {
          font-weight: 700;
          font-size: 18px;
          @include flex_box;
          padding: 0 32px;
          height: 80px;
          position: relative;

          @media screen and (max-width: $w_m) {
            height: 66px;
            display: block;
            line-height: 66px;
            background: #FFF;
          }

          &::after {
            content: "";
            height: 8px;
            position: absolute;
            background: $color_red;
            bottom: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            transition: $tr_op;

            @media screen and (max-width: $w_m) {
              bottom: auto;
              height: 100%;
              width: 6px;
            }
          }
        }

        div {
          position: absolute;
          top: 100%;
          box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.15);
          width: 175%;
          opacity: 0;
          pointer-events: none;
          transition: $tr_op;
          background: #FFF;

          @media screen and (max-width: $w_m) {
            box-shadow: none;
            width: 100%;
            background: #f8f8f8;
            position: unset;
            opacity: 1;
            pointer-events: inherit;
            display: none;
          }

          a {
            display: block;
            padding: 16px 24px;

            font-weight: 700;
            font-size: 18px;
            transition: $tr_c;

            @media screen and (max-width: $w_m) {
              padding: 0 32px;
              height: 66px;
              line-height: 66px;
            }

            &:hover {
              color: $color_red;
            }
          }
        }
      }
    }
  }
}

footer {
  padding: 7rem 0;
  background: #FFF;

  @media screen and (max-width: $w_m) {
    padding: 4.5rem 0
  }

  img {
    @media screen and (max-width: $w_m) {
      width: 24px;
    }
  }

  p {
    padding: 0.5rem 0;

    &.title {
      font-size: 24px;
      font-weight: 900;

      @media screen and (max-width: $w_m) {
        font-size: 22px;
      }
    }

    &:last-child {
      @media screen and (max-width: $w_m) {
        text-decoration: underline;
      }
    }
  }
}

.btn_icon {
  background: $color_red;
  font-size: 32px;
  color: #FFF;
  font-weight: 700;
  padding: 5px 20px;
  display: table;
  letter-spacing: 0.3rem;
  margin: 2rem auto 0 auto;
  transition: $tr_c,$tr_bg;
  border: 1px solid $color_red;

  &:hover{
    color: $primary;
    background: #FFF;
  }

  @media screen and (max-width: $w_m) {
    font-size: 14px;
    padding: 10px 15px 10px 20px;
    margin: 0.5rem auto 0 auto;
  }
}

.in_top{ 

  &.news{
    background: url(../images/img_news_banner.jpg) no-repeat center;
    background-size:cover ;
  }

  &.form{
    background: url(../images/img_urban_renew_banner.jpg) no-repeat center;
    background-size:cover ;
  }

  &.c_case{
    background: url(../images/img_case_banner.jpg) no-repeat center;
    background-size:cover ;
  }

  .w_box{
    display: flex;
    align-items: flex-end;
    height: 250px;

    @media screen and (max-width: $w_m) {
      height: 130px;
    }

    h2{
      font-weight: 600;
      background: #FFF;
      padding: 10px 5px 10px 20px;
      letter-spacing: 14.4px;
      margin: 0;
      font-size: 48px;
      line-height: 68.98px;

      @media screen and (max-width: $w_m) {
        font-size: 24px;
        line-height: 34.49px;
        letter-spacing: 7.2px;
        padding: 10px 12px 10px 20px;
      }
    }
  }
}

.in_box{
  padding: 4rem 0 0 0;

  @media screen and (max-width: $w_m) {
    padding: 2rem 0 0 0;
  }
}

.page{
  list-style: none;
  @include flex_box();
  margin: 5rem 0 0 0;
  padding: 0;

  @media screen and (max-width: $w_m) {
    margin: 2rem 0 0 0;
  }

  li{
    a{
      font-size: 20px;
      font-weight: 900;
      margin: 0 0.625rem;
      transition: $tr_c;

      &:hover{
        color: $color_red;
      }

    }

    &.focus{
      a{
        color: $color_red;
      }
    }
  }
}

.back{
  font-size: 18px;
  font-weight: 900;
  margin: 0 0 3rem 0;
  display: table;

  @media screen and (max-width: $w_m) {
    margin: 0 0 20px 0;
  }
}