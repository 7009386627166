.form_box {
    .say {
        text-align: center;
        font-size: 24px;

        @media screen and (max-width: $w_m) {
            font-size: 14px;
          }
    }

    .label_box {
        width: 100%;
        max-width: 790px;
        padding: 4rem 0 0 0;        

        @media screen and (max-width: $w_m) {
            padding: 2rem 0 0 0;
            max-width: 100%;
        }

        &.f{
            max-width: 100%;
        }

        label {
            display: block;
            margin: 0 0 2rem 0;

            @media screen and (max-width: $w_m) {
                margin: 0 0 1rem 0;
            }

            b {
                font-weight: 700;
                font-size: 18px;
                display: block;
                padding: 0 0 1rem 0;

                @media screen and (max-width: $w_m) {
                    font-size: 14px;
                }
            }


            input {
                height: 69px;
                background: #FFF;
                width: 100%;
                border: 0;
                padding: 0 24px;
                box-sizing: border-box;
                font-size: 20px;

                &::placeholder {
                    color: rgba($primary, 0.5);
                }

                @media screen and (max-width: $w_m) {
                    height: 63px;
                    font-size: 16px;
                }
            }

        }

        .select_box {
            @include flex_box($jc:space-between);

            @media screen and (max-width: $w_m) {
                flex-flow: row wrap;
            }
            
            .select_label {
                width: calc(50% - 1rem);
                position: relative;

                @media screen and (max-width: $w_m) {
                    width: 100%;
                    margin: 0 0 1rem 0;
                }

                &:after{
                    content: "";
                    width: 67px;
                    height: 67px;                    
                    background:#FFF url(../images/icon_inputbox_dropdown.svg) no-repeat center / 16px;
                    position: absolute;
                    right: 1px;
                    bottom: 1px;
                    pointer-events: none;
                }
                
                b {
                    font-weight: 700;
                    font-size: 18px;
                    display: block;
                    padding: 0 0 1rem 0;

                    @media screen and (max-width: $w_m) {
                        font-size: 14px;
                    }
                }

                select {
                    height: 69px;
                    width: 100%;
                    padding: 0 24px;
                    border: 0;
                    color: rgba($primary, 0.5);
                    font-size: 20px;

                    &.focus{
                        color: $primary;      
                        
                        & ~ div{
                            color: $primary;      
                        }
                    }

                    @media screen and (max-width: $w_m) {
                        font-size: 16px;
                    }
                }

                .nice-select{
                    height: 69px;
                    line-height: 67px;
                    width: 100%;
                    border: 0;
                    font-size: 20px;
                    padding: 0 24px;
                    color: rgba($primary, 0.5);

                    @media screen and (max-width: $w_m) {
                        font-size: 16px;
                    }

                    .list{
                        width: 100%;
                        color: $primary;

                        .option{
                            padding: 0 24px;
                            &.selected{
                                font-weight: inherit;
                            }
                        }
                    }
                }
            }
        }

        .btn_icon{
            font-size: 20px;
            margin: 2rem auto 0 0;
            pointer-events: inherit;
            height: 49px;
            line-height: 49px;
            box-sizing: border-box;
            padding: 0 20px;

            &.gray{
                background: #E5E5E5;
                pointer-events: none;
                border: 1px solid #E5E5E5;
            }

            &.f{
                margin: 0 auto;
            }
            
            @media screen and (max-width: $w_m) {
                margin: 0 auto 0 0;
            }
        }
    }
}