.news_list {
    padding: 0;
    margin: 0;
    list-style: none;

    @media screen and (max-width: $w_m) {
        padding: 0 1rem;
    }

    li {
        @include flex_box();
        margin: 0 0 8.5rem 0;

        @media screen and (max-width: $w_m) {
            margin: 0 0 2rem 0;
            flex-flow: column;
        }

        &:last-child {
            margin: 0;
        }

        .left {
            width: 40%;
            font-size: 18px;
            font-weight: 400;
            padding: 0 2.8125rem 0 0;
            box-sizing: border-box;
            @include flex_box($ai:flex-start);
            flex-flow: column;

            @media screen and (max-width: $w_m) {
                width: 100%;
                order: 2;
                font-size: 12px;
            }

            .time {
                @media screen and (max-width: $w_m) {
                    margin: 1rem 0 0.5rem 0;
                }
            }

            .title {
                font-weight: 900;
                font-size: 32px;
                padding: 2rem 0;
                transition: $tr_c;

                &:hover {
                    color: $color_red;
                }

                @media screen and (max-width: $w_m) {
                    padding: 0 0 0.5rem 0;
                }
            }

            .text {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                /*省略第n行後的文字*/
                -webkit-box-orient: vertical;

                @media screen and (max-width: $w_m) {
                    font-size: 14px;
                }
            }

            .more {
                font-weight: 900;
                color: $color_red;
                margin: 2rem 0 0 0;

                &:hover {
                    text-decoration: underline;
                }

                @media screen and (max-width: $w_m) {
                    margin: 0.5rem 0 0 0;
                }
            }
        }

        .img_box {
            width: 60%;

            @media screen and (max-width: $w_m) {
                width: 100%;
                order: 1;
            }

            img {
                box-shadow: 1rem 1rem 0 rgba($primary, 1);

                @media screen and (max-width: $w_m) {
                    box-shadow: 0.5rem 0.5rem 0 rgba($primary, 1);
                }
            }
        }
    }
}

.news_in {
    font-size: 18px;
    font-weight: 400;

    @media screen and (max-width: $w_m) {
        font-size: 12px;
    }

    img {

    }

    .time {
        margin: 2rem 0;

        @media screen and (max-width: $w_m) {
            margin: 1rem 0 0.5rem 0;
        }
    }

    .title {
        font-size: 32px;
        font-weight: 900;
        margin: 0 0 2rem 0;

        @media screen and (max-width: $w_m) {
            margin: 0 0 0.5rem 0;
        }
    }

    .edit_box {
        @media screen and (max-width: $w_m) {
            font-size: 14px;
        }
    }
}