.c_case_box {
    padding: 0 0 calc(240px - 2rem) 0;

    @media screen and (max-width: $w_m) {
        padding: 60px 0 7.5rem 0;
    }

    .select_box {
        padding: 0 0 2rem 0;

        &.classic{
            @include flex_box($jc:space-between);

            @media screen and (max-width: $w_m) {
                flex-flow: row wrap;
            }
            
            .btn_box{
                @include flex_box($jc:flex-start);

                @media screen and (max-width: $w_m) {
                    width: 100%;
                    padding: 0 0 1rem 0;
                }

                .btn_icon{
                    margin: 0 20px 0 0;
                    font-size: 20px;
                    color: $primary;
                    background: #FFF;

                    @media screen and (max-width: $w_m) {
                        font-size: 14px;
                    }

                    &:last-child{
                        margin: 0;
                    }

                    &:hover{
                        background: $color_red;
                        color: #FFF;      
                    }

                    &.focus{
                        background: $color_red;
                        color: #FFF;                        
                    }
                }
            }
            .select_label{
                
            }

        }

        .select_label {
            position: relative;
            @include flex_box($jc:flex-end);

            @media screen and (max-width: $w_m) {
                @include flex_box($jc:flex-start);
            }

            b {
                font-weight: 900;
                font-size: 20px;
                display: block;
                padding: 0 1rem 0 0;

                @media screen and (max-width: $w_m) {
                    font-size: 14px;
                }
            }

            .nice-select {
                height: 49px;
                line-height: 47px;
                width: 278px;
                border: 0;
                font-size: 20px;
                padding: 0 24px;
                font-weight: 700;

                @media screen and (max-width: $w_m) {
                    font-size: 14px;
                    width: 200px;
                }

                &:after {
                    content: "";
                    width: 47px;
                    height: 47px;
                    background: #FFF url(../images/icon_inputbox_dropdown.svg) no-repeat center / 24px;
                    position: absolute;
                    right: 1px;
                    bottom: 1px;
                    pointer-events: none;
                    transform: none;
                    border: none;
                    margin: 0;
                    top: auto;
                }

                .list {
                    width: 100%;
                    color: $primary;

                    .option {
                        padding: 0 24px;

                        &.selected {
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
    }

    .case_list {
        @include flex_box();
        flex-flow: row wrap;
        list-style: none;

        li {
            width: calc((100% - 2rem) / 2);
            margin: 0 2rem 2rem 0;

            @media screen and (max-width: $w_m) {
                width: 100%;
                margin: 0 0 2rem 0;
            }

            &:nth-child(even) {
                margin: 0 0 2rem 0;
            }

            &:last-child {
                @media screen and (max-width: $w_m) {
                    margin: 0;
                }
            }

            a {
                img {
                    transition: box-shadow 0.3s ease-out;
                }

                &:hover {
                    img {
                        box-shadow: 5px 5px 0 $color_red;
                    }
                }
            }

            .text {
                .title {
                    padding: 0.5rem 0;
                    @include flex_box($jc:space-between);

                    p {
                        font-size: 32px;
                        font-weight: 900;

                        @media screen and (max-width: $w_m) {
                            font-size: 20px;
                        }
                    }

                    span {
                        font-size: 20px;
                        font-weight: 700;
                        letter-spacing: 0.3rem;
                        @include flex_box();

                        @media screen and (max-width: $w_m) {
                            font-size: 12px;
                        }

                        &:before {
                            content: "";
                            width: 10px;
                            height: 10px;
                            background: $color_red;
                            display: inline-block;
                            margin: 0 0.5rem 0 0;
                        }
                    }
                }

                >p {
                    font-size: 14px;

                    @media screen and (max-width: $w_m) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .case_in {
        margin: 0 0 3rem 0;
        .title {
            font-size: 48px;
            font-weight: 900;
            border-bottom: 4px solid $color_red;
            padding: 0 0 8px 0;
            display: table;
            margin: 0 0 3rem 0;

            @media screen and (max-width: $w_m) {
                font-size: 24px;
                border-bottom: 2px solid $color_red;
            }
        }

        p {
            position: relative;
            font-size: 20px;
            font-weight: 700;
            margin: 0 0 12px 0;
            padding: 0 0 0 18px;
            line-height: 28.74px;
            

            @media screen and (max-width: $w_m) {
                font-size: 14px;
                line-height: 20.12px;
                letter-spacing: 4.2px;
            }

            &:before {
                content: "";
                width: 10px;
                height: 10px;
                background: $color_red;
                display: inline-block;
                margin: 0 0.5rem 0 0;
                position: absolute;
                left: 0;
                top: 10px;
            }
        }
    }

    .grid {
        margin: 0 -1rem;

        @media screen and (max-width: $w_m) {
            margin: 0;
        }

        .grid-item {
            width: calc((100% - 4rem) / 2);
            margin: 0 1rem 2rem 1rem;

            @media screen and (max-width: $w_m) {
                width: 100%;
                margin: 0 0 1rem 0;
            }

            &:last-child{
                @media screen and (max-width: $w_m) {
                    margin: 0;
                }   
            }
        }
    }

}